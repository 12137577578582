<template>
  <div v-class-on-scroll="'show'" class="navigation">
    <div @click="back" class="back">
      <img :src="require('./arrow.svg')" alt="back" />
    </div>
    <div v-class-on-scroll="'show'" class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  directives: {
    "class-on-scroll": {
      inserted(el, bind) {
        window.addEventListener("scroll", () => {
          el.classList.toggle(bind.value, window.scrollY > 0);
        });
      }
    }
  },
  props: {
    text: {
      type: String,
      default: () => ""
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation {
  padding: 0;
  position: sticky;
  top: 0;
  background: transparent;
  transition: background 1s ease-in-out;
  z-index: 999;

  &.show {
    background: var(--background);
  }

  .back {
    padding: 33px 30px;
    display: inline-block;
    cursor: pointer;

    img {
      display: block;
      height: 12px;
    }
  }

  .text {
    position: absolute;
    width: calc(100% - 67px * 2);
    white-space: nowrap;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    opacity: 0;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 1s ease-in-out;

    &.show {
      opacity: 1;
    }
  }
}
</style>
