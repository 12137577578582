<template>
  <div class="where-code">
    <Navigation text="Torna indietro" />

    <h1>Dove trovo il<br />codice di accesso?</h1>

    <div class="description">
      Il codice d’accesso è applicato su un’etichetta all’interno dello
      sportello del contatore della luce presente in entrata.
    </div>

    <img src="//via.placeholder.com/300x200" alt="x" class="picture" />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";

export default {
  components: {
    Navigation
  }
};
</script>

<style lang="scss" scoped>
.where-code {
  padding-bottom: 91px;

  h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 43px;
    text-align: center;
    color: var(--light-brown);
    margin-bottom: 61px;
  }

  .description {
    font-weight: 450;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    width: 300px;
    margin: 0 auto;
    margin-bottom: 44px;
  }

  .picture {
    display: block;
    margin: 0 auto;
    border-radius: 13px;
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.12));
  }
}
</style>
