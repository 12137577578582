<template>
  <div class="scan">
    <qrcode-stream @decode="onScan"></qrcode-stream>

    <BigButton
      class="button"
      :class="{ ready }"
      text="Entra"
      @click.native="go"
    />
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import BigButton from "@/components/BigButton";

export default {
  components: {
    BigButton,
    QrcodeStream
  },
  data: () => ({
    ready: false,
    lastScan: undefined
  }),
  methods: {
    onScan(context) {
      this.lastScan = context;
      if (!this.lastScan.includes(window.location.origin)) return;

      this.ready = true;
    },
    go() {
      window.location.href = this.lastScan;
    }
  }
};
</script>

<style lang="scss" scoped>
.scan {
  height: 100vh;

  video {
    width: 100%;
    height: 100%;
  }

  .button {
    position: fixed;
    bottom: 100px;
    width: calc(100% - 60px);
    left: 30px;
    opacity: 0;
    z-index: 999;
    transition: opacity 0.25s ease-in-out;

    &.ready {
      opacity: 1;
    }
  }
}
</style>
