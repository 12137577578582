<template>
  <button class="button">{{ text }}</button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  background: var(--light-brown);
  border: none;
  color: var(--white);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 18px 27px;
  border-radius: 72px;
  width: 100%;
  display: block;
  margin: 0 auto;
  white-space: nowrap;
}
</style>
